$(function () {

    var body = $('body');

    // $('table.access').not('.no-dblclick').find('tr td').on('dblclick', function () {
    //     var href = $(this).parents('tr').eq(0).find('.f-update-button').attr('href');
    //     if (href) {
    //         window.location.href = href;
    //     }
    //
    //     return true;
    // });

    body.on('click', '.selectable', function () {
        var input = $(this).find('input[type="checkbox"]');
        input.trigger('click');
        if (input.prop('checked')) {
            $(this).addClass('selected');
            $(this).closest('tr').addClass('table-warning');
        } else {
            $(this).removeClass('selected');
            $(this).closest('tr').removeClass('table-warning');
        }
    });

    $('.open-prices').on('click', function () {
        var el = $(this);
        var icon = el.find('.fa');
        var tr = el.closest('tr');

        var object_id = el.data('object_id');
        var id = 'price-tr-' + object_id;


        if (el.hasClass('opened')) {
            icon.addClass('fa-plus').removeClass('fa-minus');
            el.removeClass('opened');
            $('#' + id).remove();
        } else {
            var pricetr = $('<tr>', {id: id, class: 'subrow'});
            var pricetd = $('<td>', {colspan: 1000});
            var pricecontainer = $('<div>', {class: 'open-price-container'});

            pricetd.append(pricecontainer);
            pricecontainer.text('загрузка');
            icon.removeClass('fa-plus').addClass('fa-minus');

            var url = $(this).data('url');

            pricetr.append(pricetd);
            tr.after(pricetr);

            $.post(url, {}, function (data) {
                pricecontainer.html(data);
            });

            el.addClass('opened');
        }

        return false;
    });

    body.on('click', '.collapsible', function () {
        $(this).toggleClass('collapsible-active');
        var target = $($(this).data('target'));
        if (target.length) {
            target.toggle();
        }
    });

    body.on('click', 'td.full-cell-click', function (e) {
        e.preventDefault();

        var link = $(this).find("a").first();
        var url = link.attr('href');
        var target = link.attr('target');

        if(url) {
            if(target === '_blank') {
                window.open(url);
            }else {
                window.location.href = url;
            }
        }
        return false;
    });


    body.on('change', ".input_float,input[name$='_float]']", function () {
        var value = $(this).val();

        if (value.indexOf(',') > -1) {
            value = value.split(',').join('.');
            $(this).val(value);
        }

        if (/\s/.test(value)) {
            value = value.split(' ').join('');
            $(this).val(value);
        }

        return true;
    });

    body.on('change', ".input_integer,input[name$='_int]']", function () {

        var value = $(this).val();

        if (/\s/.test(value)) {
            value = value.split(' ').join('');
            $(this).val(value);
        }

        value = $(this).val();

        console.log(value);
        var formattedValue = parseInt(value);
        if(value && formattedValue) {
            $(this).val(formattedValue);
        } else {
            $(this).val('');
        }

        return true;
    });

    var opened_dropdown = false;
    body.on('click', '.dropdown-toggle', function(){
        var parent = $(this).closest('.dropdown');
        var el = $(parent).find('.dropdown-menu');
        el.toggleClass('d-block');
        opened_dropdown = el.hasClass('d-block');
        return false;
    }).on('click', function(){
        if(opened_dropdown){
            $('.dropdown-menu').removeClass('d-block');
            opened_dropdown = false;
        }
    });


    $('.open-row').on('click', function(){

        var el = $(this);
        var icon = el;
        var tr = el.closest('tr');

        var trait_id = el.data('data-trait_id');
        var id = 'price-tr-' + trait_id;

        if (el.hasClass('opened')) {
            icon.addClass('fa-plus').removeClass('fa-minus');
            el.removeClass('opened');
            $('#' + id).remove();
        } else {

            var subRow = $('<tr>', {id: id, class: 'subrow'});
            var subCell = $('<td>', {colspan: 1000});
            var subContainer = $('<div>', {class: 'open-price-container'});

            subCell.append(subContainer);
            subContainer.text('загрузка');
            icon.removeClass('fa-plus').addClass('fa-minus');

            var url = $(this).data('url');

            subRow.append(subCell);
            tr.after(subRow);

            $.post(url, {}, function (data) {
                subContainer.html(data);
            });

            el.addClass('opened');
        }

        return false;
    });

});