$(function(){

	var gridViewFilterWidgetSelector = '.widget-container';
	var gridViewFilterWidgetPopupSelector = $('.widget-popup');
	var gridViewFilterToggleSelector = '.widget-popup-toggle';

	$('body').on('click.gridviewwidget', function(){
		$(gridViewFilterWidgetPopupSelector).addClass('hidden');
	});

	$(gridViewFilterWidgetSelector).on('click', gridViewFilterToggleSelector, function(e){

		var cont = $(this).closest(gridViewFilterWidgetSelector);

		var popup = cont.find(gridViewFilterWidgetPopupSelector);

		$(gridViewFilterWidgetPopupSelector, gridViewFilterWidgetSelector).not(popup).addClass('hidden');

		if(popup.hasClass('hidden')){
			popup.removeClass('hidden');
			if(popup.find('input').length > 0){
				popup.find('input').eq(0).focus();
			}
		}else{
			popup.addClass('hidden');
		}

		e.stopPropagation();

	});

	$(gridViewFilterWidgetSelector).on('click', gridViewFilterWidgetPopupSelector, function(e){
		e.stopPropagation();
   });

});